.loader {
  transform: translateX(-100%);
  animation: walk 10s steps(10, end) infinite;
}

@keyframes walk {
  to {
    transform: translateX(0%);
  }
}
